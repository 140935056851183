<template>
    <div class="container">
        <b-modal title="Product" id="modal" no-close-on-esc hide-footer @hidden="model={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-12">
                            <validated-input name="Name" label="Name" v-model="model.name" :rules="rules.name"/>
                        </div>
                        <div class="col-md-12">
                            <validated-input name="Description" label="Description" v-model="model.description"
                                             :rules="rules.description"/>
                        </div>
                        <div class="col-md-12 vue-select-dropdown mb-3">
                            <label>Category</label>
                            <b-form-select name="Category" label="Category" v-model="model.category_id"
                                           :rules="rules.category" :options="choices.category"></b-form-select>

                        </div>
                        <div class="col-md-12">
                            <validated-input name="Selling Price" label="Selling Price" v-model="model.unit_price"
                                             :rules="rules.price" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-12">
                            <validated-input name="Display Price" label="Display Price" v-model="model.display_price"
                                             :rules="rules.price" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-12">
                            <b-button type="submit" variant="success">
                                Save
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

        <b-modal title="Images" id="imageTable" size="xl" no-close-on-esc hide-footer>
            <product-images :product="product"></product-images>
        </b-modal>

        <b-card class="mt-4">
            <b-card-title>
                <b-button variant="success" size="sm" class="float-right" @click="openModal">New Product</b-button>
                <h3>Catalog</h3>
            </b-card-title>
            <div class="responsive-table">
                <vue-table :data="data" :fields="fields" :url="url" ref="table">
                    <template #unit_price="{rowData}">
                        <span>{{ rowData.unit_price/100 }} </span>
                    </template>
                    <template #display_price="{rowData}">
                        <span>{{ rowData.display_price/100 }} </span>
                    </template>
                    <template #image="{rowData}">
                        <img :src="rowData.icon_path" style="max-width: 50px"
                             class="rounded-circle" alt="">
                    </template>
                    <template #action="{rowData}">
                        <b-button-group>
                            <b-button size="sm" variant="success" @click="editItem(rowData)">Edit</b-button>
                            <b-button v-if="rowData.enabled" size="sm" variant="danger" @click="changeStatus(rowData)">
                                Disable
                            </b-button>
                            <b-button v-else size="sm" variant="success" @click="changeStatus(rowData)">Enable</b-button>

                            <b-button size="sm" variant="danger" @click="deleteItem(rowData)">Delete</b-button>
                            <b-button size="sm" variant="warning" @click="imageList(rowData)">Images</b-button>
                        </b-button-group>
                    </template>
                </vue-table>
            </div>
        </b-card>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'secure-axios';
import urls from '@data/urls';
import ProductImages from './ProductImages';

export default {
    name: 'Catalog',
    components: { ProductImages },
    data () {
        return {
            product: '',
            categories: [],
            model: {
                name: '',
                description: '',
                category_id: '',
                unit_price: ''
            },
            rules: {
                name: {
                    required: true
                },
                description: {
                    required: true
                },
                category: {
                    required: true
                },
                price: {
                    required: true
                }
            },
            url: urls.product.list,
            category: [],
            data: [],
            choices: {
                category: []
            },
            fields: [
                {
                    name: 'id',
                    title: 'ID',
                    sortField: 'id'
                },
                {
                    name: 'name',
                    title: 'Name',
                    sortField: 'name'
                },
                {
                    name: 'description',
                    title: 'Description',
                    sortField: ''
                },
                {
                    name: 'category.text',
                    title: 'Category',
                    sortField: 'category'
                },
                {
                    name: '__slot:unit_price',
                    title: 'Price',
                    sortField: 'price'
                },
                {
                    name: '__slot:display_price',
                    title: 'Display Price',
                    sortField: 'display_price'
                },
                {
                    name: '__slot:action',
                    title: 'Action'
                }
            ]
        };
    },
    beforeMount () {
        this.loadData();
        this.imageList();
    },

    methods: {
        openModal () {
            this.$bvModal.show('modal');
        },

        setData (response) {
            this.$refs.table.refreshTable();
            if (response.data.Category) {
                this.categories = response.data.Category;
            }
        },

        async onSubmit () {
            let url;
            if (this.model.id) {
                url = urls.product.update;
                const response = await axios.form(url, this.model);
                if (response.data.error === 'true') {
                    console.log('error error');
                    this.$refs.formValidator.setErrors(response.data.errors);
                } else {
                    console.log('level_1');
                    this.$refs.table.refreshTable();
                    console.log('level_2');
                    this.$bvModal.hide('modal');
                    console.log('level_3');
                }
            } else {
                url = urls.product.create;
                const response = await axios.form(url, this.model);
                if (response.data.error) {
                    this.$refs.formValidator.setErrors(response.data.errors);
                } else {
                    this.setData(response);
                    this.$bvModal.hide('modal');
                }
            }
        },
        async loadData () {
            const response = await axios.get(urls.categories.dropdownlist);
            console.log('dropdownlist-response', response.data.Category);
            this.choices.category = response.data.Category;
        },

        async editItem (rowData) {
            console.log('rowData', rowData.category.value);
            this.model = {
                id: rowData.id,
                name: rowData.name,
                description: rowData.description,
                category_id: rowData.category.value,
                unit_price: rowData.unit_price / 100,
                display_price: rowData.display_price / 100
            };
            this.openModal();
        },

        async deleteItem (rowData) {
            const confirm = await this.$bvModal.msgBoxConfirm('Are you sure ?');
            if (confirm) {
                const params = { id: rowData.id };
                const response = await axios.form(urls.NewAPIs.ProdDelete, params);
                this.setData(response);
            }
        },
        async imageList (rowData) {
            console.log('this.rowData', rowData);
            this.product = rowData.id;
            this.$bvModal.show('imageTable');
        },
        isNumber: function (evt) {
            evt = (evt) || window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        },
        changeStatus (rowData) {
            if (!confirm('Are you sure?')) {
                return;
            }
            const component = this;
            axios.form(urls.NewAPIs.ChangeStatus, {
                id: rowData.id
            }).then(function (data) {
                const json = data.data;
                console.log(json);
                if (json.error === 'false') {
                    component.$notify({
                        message: 'Updated successfully',
                        type: 'success'
                    });
                    rowData.enabled = !rowData.enabled;
                } else {
                    component.saving = false;
                    alert('Error...');
                }
            });
        }
    }
};

</script>
